import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import START from "../../images/Start.svg"
import { Link } from "gatsby"

const Start = () => (
  <TutorialLayout
    imageSrc={START}
    title="Acum poți deschide containerul cu telefonul!"
    buttonLink="https://app.myara.ro"
    buttonText="Deschide aplicația MyAra ->"
    description={
      "Dacă ai urmat pașii acum poți deschide insula digitală folosind telefonul. Ține telefonul cu aplicația deschisa deasupra spațiului indicat timp de 4-5 secunde astfel încât telefonul cu pictograma vizivilă să acopere o suprafață cât mai mare a cititorului și containerul se va deschide (vă rugăm să urmăriți și legenda cu signalistica ledurilor de pe cititor)."
    }
  >
    <Link to="/salut">
      <button
        style={{
          width: "100%",
          backgroundColor: "#fff",
          height: 48,
          border: "1px solid #ABB2BA",
          cursor: "pointer",
          color: "#2E3338",
          borderRadius: 8,

          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Inter",
        }}
      >
        Reîncepe tutorialul
      </button>
    </Link>
  </TutorialLayout>
)

export default Start

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode, useEffect } from "react"
import PropTypes from "prop-types"
import NavBar from "./NavBar"
import { I18NextWrapper } from "../loaders/i18nextInit"
import { Loading } from "./Loading"
import { goToSection } from "../utils";
import '../styles/index.css'

interface AppWrapperProps {
  children: ReactNode
}

const TutorialAppWrapper = ({ children }: AppWrapperProps) => {
  useEffect(() => {
    const sectionId = window.location.hash.substring(1)
    setTimeout(() =>
      goToSection(sectionId), 200)
  }, [])

  return (
    <>
      <I18NextWrapper loading={<Loading />}>
        <>
          <header>
            <NavBar />
          </header>
          <main style={{ maxWidth: 360, margin: "0 auto", marginTop: 120, padding: "0px 20px 0px 20px" }}>
            {children}
          </main>
        </>
      </I18NextWrapper>
    </>
  )
}

TutorialAppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TutorialAppWrapper
